import { createSlice } from '@reduxjs/toolkit'
import { APP_BAR_STATE, LOADING_KEYS, REGISTRATION_KEYS, USER_TYPE } from "../../../../lib/constants"

const initialState = {
  networkInfo: {},
  appBar: {
    state: APP_BAR_STATE.HOME,
    label: '',
    backLocation: null,
  },
  canonicalTopics: [],
  canonicalTopicMap: null,
  userRequests: [],
  activeRequests: [],
  activeRequestMap: {},

  // Connection Related
  connections: [],
  totalConnections: 0,
  connectedNetworks: [],

  user: {},
  callLogs: [],
  onboard: {
    [REGISTRATION_KEYS.USER_TYPE]: USER_TYPE.NORMAL
  },
  availability: {},
  // loading[key] = bool
  loading: {
    [LOADING_KEYS.FIRST_LOAD]: true,
    [LOADING_KEYS.SECOND_LOAD]: true,
  },
  snackbar: {
    open: false,
    text: ''
  }
}

const system_slice = createSlice({
  name: 'system',
  initialState: initialState,
  reducers: {
    saveCanonicalTopics(state, action) {
      state.canonicalTopics = action.payload;
      state.canonicalTopicMap = {};
      const addTopicsToMap = (topic, parentTopicId) => {
        state.canonicalTopicMap[topic['id']] = topic;
        state.canonicalTopicMap[topic['id']]['ParentId'] = parentTopicId
        if (topic['SubTopics']) {
          topic['SubTopics'].forEach((subTopic) => addTopicsToMap(subTopic, topic['id']));
        }
      }
      action.payload.forEach((topLevelTopic) => addTopicsToMap(topLevelTopic, null))
      return state;
    },
    setAppBar(state, action) {
      state.appBar.state = action.payload.state;
      state.appBar.label = action.payload.label || '';
      state.appBar.backLocation = action.payload.backLocation || null;
      return state;
    },
    // This gets set when the page first loads.
    setSystemData(state, action) {
      if (Array.isArray(action.payload)) {
        action.payload.forEach((item) => {
          state[item.key] = item.value;
        });
      }
      else {
        state[action.payload.key] = action.payload.value;
      }
      return state;
    },
    setAvailability(state, action) {
      if (action.payload.field == 'reset') {
        state.availability = action.payload.value;
      } else {
        state.availability[action.payload.day] = state.availability[action.payload.day] || {}; // Default
        state.availability[action.payload.day][action.payload.field] = action.payload.value;
      }
      return state;
    },
    setOnboardData(state, action) {
      const key = action.payload.key;
      if (key == REGISTRATION_KEYS.KNOWN_TOPICS || key == REGISTRATION_KEYS.INTEREST_TOPICS) {
        state.onboard[key] = state.onboard[key] || {}; // Default to {}.
        if (state.onboard[key][action.payload.value['id']]) {
          delete state.onboard[key][action.payload.value['id']]
        } else {
          state.onboard[key][action.payload.value['id']] = true;
        }
      } else if (Array.isArray(action.payload)) {
        action.payload.forEach((item) => {
          state.onboard[item.key] = item.value;
        });
      }
      else {
        state.onboard[key] = action.payload.value;
      }
      return state;
    },
    setLoading(state, action) {
      state.loading[action.payload.key] = action.payload.value;
      return state;
    },
    setSnackbar(state, action) {
      state.snackbar = action.payload
      return state;
    },

    // action.payload == [activeRequest]
    setActiveRequests(state, action) {
      state.activeRequestMap = {};
      action.payload.forEach((request) => {
        state.activeRequestMap[request['ExternalId']] = request;
      })
      return state;
    },

    // UI stuff
    // action.payload = activeRequest['_id']
    removeActiveRequest(state, action) {
      delete state.activeRequestMap[action.payload]
      return state;
    }
  },
});

export const {
  saveCanonicalTopics,
  setAppBar,
  setSystemData,
  setOnboardData,
  setLoading,
  setSnackbar,
  setActiveRequests,
  removeActiveRequest,
  setAvailability
} = system_slice.actions
export default system_slice.reducer;

