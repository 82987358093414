export const API_ADDRESS = process.env.GATSBY_API_ADDRESS
export const FRONTEND_URL = process.env.GATSBY_FRONTEND_URL

export const createEnum = (list, offset = 1) => {
  const enums = {};
  for (let i=0;i < list.length; i++) {
    enums[list[i]] = i+offset;
  }
  return Object.freeze(enums);
}

// Mapped from backend

export const TOPIC_VISIBILITY = createEnum([
  'HIDDEN',
  'WEB',
  'APP',
])

export const TOPIC_TYPE = createEnum([
  'TOPLEVEL',
  'MAIN',
  'STUDENT',
]);

export const REQUEST_STATUS = createEnum( [
  'PENDING',
  'FULFILLED',
  'UNFULFILLED'
]);

export const REQUEST_ACTION = createEnum(['ACCEPT', 'DECLINE']);

export const REQUEST_ORIGIN = createEnum(['PLATFORM', 'WEB']);

export const USER_PHASE = createEnum([
  'PROSPECT',
  'WAITLIST',
  'APP',
  'LEAD',
  'ONBOARD'
]);

export const USER_TYPE = createEnum([
  'NORMAL',
  'STUDENT',
  'ORG',
  'ANY'
])

export const USER_SETTING_KEY = createEnum([
  'STATEMENT',
  'LINKEDIN',
  'KNOWN_TOPICS',
  'INTERESTS',
  'HANDLE',
  // Config Keys
  'EXPAND_NETWORK_ENABLED',
  'SEEN_COMMUNITY_GUIDELINES'
]);


export const FEEDBACK_STATUS = createEnum([
  'NOT_DONE',
  'HAD_CALL',
  'DONE'
])


// Keep in sync with codes in constants.py
export const API_ERROR_CODES = createEnum([
  'HAS_MESSAGE',
  'EMPTY_REQUEST',
  'INVALID_REQUEST',
  'PROSPECT_EXISTS',
  'USER_EXISTS',
  'NON_US_PHONE',
]);

export const NOTIFICATION_LAYER = createEnum(['SMS', 'EMAIL']);

export const PURPLE = '#673AB7';
export const LIGHT_PURPLE = 'rgba(103, 58, 183, 0.8)';
export const LIGHT_BLUE = '#cbe4ff';

export const PHONE_REGEX =  /^(?:\+?1[-.●]?)?\(?([0-9]{3})\)?[-.●]?([0-9]{3})[-.●]?([0-9]{4})$/;
export const LINKEDIN_REGEX = /^((https?:\/\/)?((www|\w\w)\.)?linkedin\.com\/)((([\w]{2,3})?)|([^\/]+\/(([\w|\d-&#?=])+\/?){1,}))$/;
export const EMAIL_REGEX = /^\S+@\S+[\.][0-9a-z]+$/;


export const APP_BAR_STATE = createEnum(['HOME', 'BACK_LABEL', 'LABEL', 'NONE'])

export const TOPIC_MEDICINE_ID = -1;

export const STATEMENT_CHAR_LIMIT = 160;


export const LOADING_KEYS = createEnum([
  'FIRST_LOAD',
  'SECOND_LOAD',
  'LOAD_AVAILABILITY',
  'SAVE_AVAILABILITY',
  'CONNECTIONS_LOAD'
])

export const GREENWOODX_NETWORK_KEY = 'VeMGVy3j';

export const REGISTRATION_KEYS = {
  FIRST_NAME: 'first_name',
  LAST_NAME: 'last_name',
  EMAIL: 'email',
  INVITE_CODE: 'invite_code',
  GENDER: 'gender',
  RACE: 'race',
  USER_TYPE: 'user_type',
  KNOWN_TOPICS: 'known_topics',
	OTHER_KNOWN_TOPICS: 'other_known_topics',
	INTEREST_TOPICS: 'interest_topics',
	OTHER_INTEREST_TOPICS: 'other_interests_topics',
	WILL_MENTOR: 'will_mentor',
	LINKEDIN: 'linked_in',
  STATEMENT:'statement'
};


// Admin Values
export const ADMIN_ROLE = createEnum(['SU', 'OWNER', 'ADMIN', 'MEMBER'])


// Local app setting keys
export const LAS = createEnum(['SeenCommunityGuidelines', 'CLOSED_HOW_TO_GWX'])
